import Panel from "../Panel";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isOlderThanSixMonths } from "../../utils";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";
import IconLoader from "../IconLoader";
import BinahLogo from "./binah-logo.png";
import AbbyLogo from "./abby-logo.png";
import { HealthProfileItemDataSource } from "../../services/core-api-adapter";
import { HealthMeasurementDetailMetadataItem } from "../HealthMeasurementDetail";

const PROVIDER_IMAGE_DIMENSIONS = 60;

interface HealthMeasurementVerificationStatusProps {
  healthMeasurementDetailMetaData:
    | HealthMeasurementDetailMetadataItem[]
    | undefined;
}

export default function HealthMeasurementDetailVerificationStatus({
  healthMeasurementDetailMetaData: healthMeasurementData,
}: HealthMeasurementVerificationStatusProps) {
  const { t } = useTranslation();

  const isVerified =
    healthMeasurementData?.find(
      (healthMeasurementItem) => "isVerified" in healthMeasurementItem
    )?.isVerified || false;

  const originateFrom = healthMeasurementData?.find(
    (healthMeasurementItem) => "originatedFrom" in healthMeasurementItem
  )?.originatedFrom as HealthProfileItemDataSource;

  const lastUpdated =
    healthMeasurementData?.find(
      (healthMeasurementItem) => "lastUpdated" in healthMeasurementItem
    )?.lastUpdated || null;

  function isDataOriginatingFromBinah(
    originateFrom: HealthProfileItemDataSource
  ) {
    return originateFrom === HealthProfileItemDataSource.BINAH;
  }

  function getVerificationSourceImage(
    originateFrom: HealthProfileItemDataSource
  ) {
    if (isDataOriginatingFromBinah(originateFrom)) {
      return BinahLogo;
    }
    return AbbyLogo;
  }

  function getVerificationSourceImageAlternateText(
    originateFrom: HealthProfileItemDataSource
  ) {
    if (isDataOriginatingFromBinah(originateFrom)) {
      return t("HealthMeasurementVerifcationStatus.binahLogoAlternateText");
    }
    return t("HealthMeasurementVerificationStatus.abbyLogoAlternateText");
  }

  function getVerifiedStatusText() {
    if (lastUpdated && isOlderThanSixMonths(new Date(lastUpdated))) {
      return t("HealthMeasurementVerificationStatus.verified.old.text");
    }

    if (originateFrom === HealthProfileItemDataSource.BINAH) {
      return t("HealthMeasurementVerificationStatus.verified.binah.text");
    }

    return t("HealthMeasurementVerificationStatus.verified.abby.text");
  }

  return (
    <Panel>
      <Stack direction="row" alignItems="center">
        <Box mr={4}>
          <Box
            sx={{
              boxShadow: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: PROVIDER_IMAGE_DIMENSIONS,
              height: PROVIDER_IMAGE_DIMENSIONS,
            }}
          >
            {isVerified ? (
              <Box
                component="img"
                sx={{ width: "100%" }}
                src={getVerificationSourceImage(originateFrom)}
                alt={getVerificationSourceImageAlternateText(originateFrom)}
              />
            ) : (
              <FaceIconBrandLarge
                sx={{
                  height: PROVIDER_IMAGE_DIMENSIONS,
                  width: PROVIDER_IMAGE_DIMENSIONS,
                }}
              />
            )}
          </Box>
          {isVerified && (
            <Box
              sx={{
                boxShadow: 3,
                height: 33,
                width: 33,
                position: "absolute",
                bottom: (theme) => theme.spacing(0),
                left: (theme) => theme.spacing(9.5),
                backgroundColor: (theme) => theme.palette.background.paper,
              }}
            >
              {lastUpdated && isOlderThanSixMonths(new Date(lastUpdated)) ? (
                <IconLoader icon="CheckVerifiedFilledIcon" color="secondary" />
              ) : (
                <IconLoader icon="CheckVerifiedFilledIcon" color="primary" />
              )}
            </Box>
          )}
        </Box>
        <Stack>
          {isVerified ? (
            <Typography variant="h5">{getVerifiedStatusText()}</Typography>
          ) : (
            <Typography variant="h5">
              {t(
                `HealthMeasurementVerificationStatus.usersubmitted.default.text`
              )}
            </Typography>
          )}

          <Typography variant="subtitle2">
            {lastUpdated?.split("T")[0]}
          </Typography>
        </Stack>
      </Stack>
    </Panel>
  );
}
