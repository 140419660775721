import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { slideUpModalStyle } from "../../theme";
import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "../DefaultDialog";

export default function HealthMeasurementDetailModal({
  isOpen,
  onClose,
  extras = {},
}: DefaultDialogImplementationProps) {
  const { t } = useTranslation();
  const translationKey = `HealthProfile.${extras.healthMeasurementIdentifier}.detail`;

  return (
    <DefaultDialog
      label={t(`${translationKey}.title` as any)}
      isOpen={isOpen}
      onClose={onClose}
      closeButton={false}
      fullWidth={true}
      animate={true}
      paperStyleProps={{
        ...slideUpModalStyle,
      }}
    >
      <Stack spacing={3}>
        <Typography textAlign="center" variant="h2">
          {t(`${translationKey}.Modal.title` as any)}
        </Typography>

        {(
          t<any, any, string[]>(`${translationKey}.Modal.body`, {
            returnObjects: true,
          }) as []
        ).map((text: string) => (
          <Typography key={text} textAlign="left" fontWeight={600}>
            {text}
          </Typography>
        ))}

        <Stack bgcolor="neutral.100" p={2} borderRadius={2}>
          <Typography textAlign="center" sx={{ fontWeight: 600, pb: 1 }}>
            {t(`${translationKey}.MoreInfo.title` as any)}
          </Typography>

          <Stack spacing={1.5}>
            {(
              t<any, any, string[]>(`${translationKey}.MoreInfo.body`, {
                returnObjects: true,
              }) as []
            ).map((text: string) => (
              <Typography key={text} textAlign="left" variant="subtitle1">
                {text}
              </Typography>
            ))}
          </Stack>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            variant="outlined"
            aria-label={t("common.backButton")}
            onClick={() => onClose()}
            fullWidth
          >
            {t("common.backButton")}
          </Button>
        </Stack>
      </Stack>
    </DefaultDialog>
  );
}
