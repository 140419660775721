import React from "react";
import { useGauge } from "use-gauge";
import { convertPxToRem } from "../../utils";
import theme from "../../theme";

interface HealthMeasurementGaugeDataValueRange {
  min: number;
  max: number;
  healthRiskIndicator: string;
  color: string;
}

interface Props {
  value: number;
  unit: string;
  valueRange: HealthMeasurementGaugeDataValueRange[];
  numberOfTicks: number;
  healthMeasurementIdentifier: string;
}

const GAUGE_OPTIONS = {
  START_ANGLE: 90,
  END_ANGLE: 270,
  DIAMETER: 215,
  CHART_SECTION_OFFSET: 30,
  CHART_SECTION_STROKE_WIDTH: 24,
};

function fillWithCurrentMeasurementColor(
  value: number,
  valueRange: HealthMeasurementGaugeDataValueRange[]
): string | undefined {
  const maxValueRange = valueRange.reduce((prev, curr) =>
    curr.max > prev.max ? curr : prev
  );

  if (value > maxValueRange.max) {
    return maxValueRange.color;
  }

  const rangeColor =
    valueRange.find((range) => value >= range.min && value <= range.max)
      ?.color || undefined;

  return rangeColor;
}

export default function HealthMeasurementGauge({
  value,
  numberOfTicks,
  valueRange,
  healthMeasurementIdentifier,
}: Props) {
  const sortedValueRange = [...valueRange].sort((a, b) => b.max - a.max);
  const min = sortedValueRange[sortedValueRange.length - 1].min;
  let max = sortedValueRange[0].max;

  if (value > max) {
    max = value;
  }
  const gauge = useGauge({
    domain: [min, max],
    startAngle: GAUGE_OPTIONS.START_ANGLE,
    endAngle: GAUGE_OPTIONS.END_ANGLE,
    diameter: GAUGE_OPTIONS.DIAMETER,
    numTicks: numberOfTicks,
  });

  const needle = gauge.getNeedleProps({
    value: value,
    baseRadius: 12,
    tipRadius: 4,
  });

  const { width, height, viewBox } = gauge.getSVGProps();

  return (
    <div
      role="meter"
      aria-label={healthMeasurementIdentifier}
      aria-valuenow={value}
      style={{ padding: "1rem", width: "fit-content" }}
    >
      <svg
        style={{ overflow: "visible", padding: "1rem" }}
        viewBox={viewBox}
        height={height}
        width={width}
      >
        <g>
          <path
            {...gauge.getArcProps({
              offset: GAUGE_OPTIONS.CHART_SECTION_OFFSET,
              startAngle: GAUGE_OPTIONS.START_ANGLE,
              endAngle: GAUGE_OPTIONS.END_ANGLE,
            })}
            fill="none"
            stroke={fillWithCurrentMeasurementColor(value, sortedValueRange)}
            strokeLinecap="round"
            strokeWidth={GAUGE_OPTIONS.CHART_SECTION_STROKE_WIDTH}
          />
          {sortedValueRange.map((el) => {
            return (
              <path
                key={`${el.min}-${el.max}`}
                {...gauge.getArcProps({
                  offset: GAUGE_OPTIONS.CHART_SECTION_OFFSET,
                  startAngle: gauge.valueToAngle(el.min),
                  endAngle: gauge.valueToAngle(el.max),
                })}
                fill="none"
                stroke={el.color}
                strokeLinecap="round"
                strokeWidth={GAUGE_OPTIONS.CHART_SECTION_STROKE_WIDTH}
              />
            );
          })}
        </g>
        <g>
          {gauge.ticks.map((angle) => {
            let asValue = gauge.angleToValue(angle);
            const highlightTick = Math.round(value) === asValue;
            const showTickLabel = !!sortedValueRange.find((el) => {
              if (Math.round(el.min) === asValue) {
                asValue = el.min;
                return true;
              } else if (Math.round(el.max) === asValue) {
                asValue = el.max;
                return true;
              }
              return false;
            });

            return (
              <React.Fragment key={`tick-group-${angle}`}>
                <line
                  stroke={
                    highlightTick
                      ? fillWithCurrentMeasurementColor(value, sortedValueRange)
                      : theme.palette.primary[500]
                  }
                  strokeWidth={showTickLabel || highlightTick ? 2 : 0.5}
                  strokeLinecap="round"
                  {...gauge.getTickProps({ angle, length: 6 })}
                />
                {showTickLabel && (
                  <text
                    style={{
                      fontSize: convertPxToRem(12),
                      lineHeight: convertPxToRem(10),
                      fontWeight: 600,
                      fill: theme.palette.primary[500],
                    }}
                    {...gauge.getLabelProps({ angle, offset: 12 })}
                  >
                    {asValue}
                  </text>
                )}
              </React.Fragment>
            );
          })}
        </g>
        <g>
          <circle
            fill={theme.palette.background.paper}
            {...needle.base}
            r={20}
          />
          <circle
            fill={theme.palette.primary[300]}
            cx={needle.base.cx}
            cy={needle.base.cy}
            r={10}
          />
          <line
            stroke={theme.palette.primary[300]}
            strokeLinecap="round"
            strokeWidth={3}
            x1={needle.base.cx}
            x2={needle.tip.cx * 0.9}
            y1={needle.base.cy}
            y2={needle.tip.cy * 0.9}
          />
        </g>
        <text
          x={needle.base.cx}
          y={needle.base.cy + 50}
          textAnchor="middle"
          style={{
            fontSize: convertPxToRem(36),
            lineHeight: convertPxToRem(39),
            fontWeight: 700,
            fill: fillWithCurrentMeasurementColor(value, sortedValueRange),
          }}
        >
          {value}
        </text>
      </svg>
    </div>
  );
}
